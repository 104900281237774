import styled from 'styled-components/macro'
import Intro from './components/Intro'

const StyledHeading = styled.h1`
	color: red;
`

function App() {
	return (
		<>
			<Intro />
		</>
	)
}

export default App
